import {IconButton, LinearProgress, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import JobTypeCard from '@biostrand/components/src/jobs/JobTypeCard';
import {useDispatch, useSelector} from "react-redux";
import {refreshJobTypesAction} from "@biostrand/components/src/jobs/jobsSlice";
import {isJobTypesLoadingSelector, jobTypesSelector} from "@biostrand/components/src/jobs/jobsSelectors";
import {ProtoJobType, ProtoJobTypeGrouping} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

interface JTUIGroup {
    group: ProtoJobTypeGrouping,
    jobTypes: ProtoJobType[]
}


const JobsOverview = (): JSX.Element => {
    const jobTypes = useSelector(jobTypesSelector);
    //const jtGroups = useSelector(jobTypeGroupsSelector);
    const isJobTypesLoading = useSelector(isJobTypesLoadingSelector);
    const dispatch = useDispatch();
    const [groups, setGroups] = useState<JTUIGroup[]>([]);
    const [groupsState, setGroupsState] = useState({});

    useEffect(() => {
        dispatch(refreshJobTypesAction());
    }, [])

    useEffect(() => {
        if (jobTypes) {
            const newGroups = []
            const jTUIGroupsById = {}

            jobTypes.forEach((jobType) => {
                jobType.groupings && jobType.groupings.forEach(jtg => {
                    let jtuiGroup: JTUIGroup = jTUIGroupsById[jtg.id];

                    if (!jtuiGroup) {
                        jtuiGroup = {
                            group: jtg,
                            jobTypes: []
                        }
                        jTUIGroupsById[jtg.id] = jtuiGroup;
                        newGroups.push(jtuiGroup)
                    }

                    jtuiGroup.jobTypes.push(jobType);

                })
            })

            // newGroups.push({
            //     group: {
            //         id: Math.PI,
            //         name: "All job",
            //         description: "List of all available jobs"
            //     },
            //     jobTypes: [
            //         ...jobTypes
            //     ]
            // })

            setGroups(newGroups);
            const gs = {}
            newGroups.forEach(g => gs[g.group.id] = true);
            setGroupsState(gs);
        }
    }, [jobTypes]);

    //console.log("isJobTypesLoading, jobTypes =>", isJobTypesLoading, jobTypes )

    const changeGroupState = (id) => {
        const newState = {...groupsState};
        newState[id] = !newState[id];
        setGroupsState(newState);
    }
    return (
        <Stack direction={'column'} sx={{position: 'relative', overflowY: 'auto', flex:1, height:'100%', justifyItem:"stretch"}}>
            {groups && groups.length ?
                groups.map(uiGroup => {
                    return (<Stack key={uiGroup.group.id} sx={{border: "1px #eee solid", mb: 2}}>
                        <Stack direction={'row'} sx={{m: 2, alignItems: "center"}} spacing={1}  onClick={() => {
                            changeGroupState(uiGroup.group.id)
                        }}>
                            <Typography variant={"body1"} sx={{fontWeight: "600"}}>{uiGroup.group.name}</Typography>
                            <Typography variant={"body2"}>{uiGroup.group.description}</Typography>
                            <Typography variant={"body2"}
                                        sx={{opacity: 0.5}}>({uiGroup.jobTypes.length} jobs)</Typography>
                            <IconButton onClick={() => {
                                changeGroupState(uiGroup.group.id)
                            }}>{groupsState[uiGroup.group.id] ? <ExpandLess/> : <ExpandMore/>}</IconButton>
                        </Stack>

                        {groupsState[uiGroup.group.id] &&
                        <Stack direction={'row'} sx={{flexWrap: 'wrap', position: 'relative', overflowY: 'auto'}}>{
                            uiGroup.jobTypes.map(jobType => (<JobTypeCard template={jobType} key={jobType.id}/>))
                        }
                        </Stack>}
                    </Stack>)
                }) : null}

            {/*{!isJobTypesLoading && jobTypes?.length && jobTypes.map(jobType => (*/}
            {/*    <JobTypeCard template={jobType} key={jobType.id} />*/}
            {/*))}*/}
            {isJobTypesLoading ? (
                <Stack sx={{width: "100%"}}>
                    <LinearProgress/>
                </Stack>
            ) : null}
        </Stack>
    )
};

export default JobsOverview;
