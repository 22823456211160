import {CloudUploadOutlined} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {useDispatch} from 'react-redux';
import {assignFileResource, kickUploadProcess} from '../../slices/datasets/fileUploadManagerSlice';
import {FileUploadInfo} from '../../slices/datasets/fileUploadTypes';
import CloseNotificationButton from '../../snackbarNotification/CloseNotificationButton';
import {enqueueSnackbarNotification} from '../../snackbarNotification/snackbarNotificationSlice';

interface Props {
  fileInfo: FileUploadInfo;
}

export const ReselectFileButton = (props: Props): JSX.Element => {
  const { fileInfo } = props;
  const dispatch = useDispatch()
  
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles, event) => {
      if (acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0].size !== fileInfo.fileSize) {
        dispatch(
          enqueueSnackbarNotification({
            message: 'Selected file has different size',
            key: 'file-selection-error-key',
            options: {
              variant: 'warning',
              persist: false,
              action: key => <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>,
            },
          }),
        );
        event && event.preventDefault();
        return;
      }

      dispatch(assignFileResource({ fileId: fileInfo.fileId, file: acceptedFiles[0] }));
      dispatch(kickUploadProcess());
    },
    [fileInfo],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

  return (
    <Stack {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton size={'small'}>
        <CloudUploadOutlined />
      </IconButton>
    </Stack>
  );
};
