import {ArrowBack} from '@mui/icons-material';
import {Button} from '@mui/material';
import {Stack} from '@mui/system';
import {push} from 'connected-react-router';
import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useParams} from 'react-router';
import {RouteUrl} from '../../../../routeUrl';
import DatasetDetails from "@biostrand/components/src/datasets/DatasetDetails";
import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {datasetByIdSelector} from "@biostrand/components/src/slices/datasets/datasetsSelectors";
import {refreshDatasetsAction} from '@biostrand/components/src/slices/datasets/datasetsSlice';

interface IDatasetParams {
    datasetName: string;
}

const DatasetDetailsSettingsContent = (): JSX.Element => {
    const {datasetName} = useParams<IDatasetParams>();



    const dispatch = useDispatch();
    const dataset: DatasetManagerDatasetEntity | undefined = useSelector(datasetByIdSelector(datasetName));


    console.log('dataset => ', dataset);
    useEffect(() => {

        console.log('datasetName => ', datasetName);
        dispatch(refreshDatasetsAction());
    }, [datasetName]);

    return (
        <Stack sx={{m: 2, flex: 1}} direction={'column'}>
            <Stack direction={'row'}>
                <Button
                    startIcon={<ArrowBack/>}
                    onClick={() => {
                        dispatch(push(RouteUrl.DataSetsSettings));
                    }}>
                    Back to datasets
                </Button>
            </Stack>

            {dataset?.name &&  <DatasetDetails datasetId={datasetName} datasetVersion={dataset?.version} />}


        </Stack>
    );
};

export default DatasetDetailsSettingsContent;
