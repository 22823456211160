import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Popover,
  Stack,
  Typography
} from '@mui/material';
import {useFormik} from 'formik';
import 'molstar/build/viewer/molstar.css';
import {BuiltInTrajectoryFormat} from 'molstar/lib/mol-plugin-state/formats/trajectory';
import {StructureSelectionQuery} from 'molstar/lib/mol-plugin-state/helpers/structure-selection-query';
import {createPluginUI} from 'molstar/lib/mol-plugin-ui/';
import {parse} from 'molstar/lib/mol-script/transpile';
import {examples} from 'molstar/lib/mol-script/transpilers/pymol/examples'
import React, {useEffect, useRef, useState} from 'react';
import {HelpOutlineOutlined, Launch} from "@mui/icons-material";
import {PluginSpec} from "molstar/lib/mol-plugin/spec";
import {StateActions} from "molstar/lib/mol-plugin-state/actions";
import {
  BoxifyVolumeStreaming,
  CreateVolumeStreamingBehavior,
  InitVolumeStreaming
} from "molstar/lib/mol-plugin/behavior/dynamic/volume-streaming/transformers";
import {StateTransforms} from "molstar/lib/mol-plugin-state/transforms";
import {AssignColorVolume} from "molstar/lib/mol-plugin-state/actions/volume";
import {PluginBehaviors} from "molstar/lib/mol-plugin/behavior";
import {StructureFocusRepresentation} from "molstar/lib/mol-plugin/behavior/dynamic/selection/structure-focus-representation";
import {AnimateModelIndex} from "molstar/lib/mol-plugin-state/animation/built-in/model-index";
import {AnimateCameraSpin} from "molstar/lib/mol-plugin-state/animation/built-in/camera-spin";
import {AnimateCameraRock} from "molstar/lib/mol-plugin-state/animation/built-in/camera-rock";
import {AnimateStateInterpolation} from "molstar/lib/mol-plugin-state/animation/built-in/state-interpolation";
import {AnimateAssemblyUnwind} from "molstar/lib/mol-plugin-state/animation/built-in/assembly-unwind";
import {AnimateStateSnapshots} from "molstar/lib/mol-plugin-state/animation/built-in/state-snapshots";
import {AnimateStructureSpin} from "molstar/lib/mol-plugin-state/animation/built-in/spin-structure";
import {getMolstarContentType, isSupportedByMolstar} from "./MolstarUtils";
import {getFileExtension} from "../datasets/fileupload/fileUtils";
import {loadTextFile} from "../datasets/fileViewer/loadFile";
import {getFileFolder, getFileName} from "../datasets/fileViewer/filePreviewUtils";
import {LoadingButton} from "@mui/lab";

interface MolstarViewerProps {
  data: string;
  contentType: string;
  label: string;
  fileList: []
}

export const CustomPluginSpec = (): PluginSpec => ({
  actions: [
    PluginSpec.Action(StateActions.Structure.DownloadStructure),
    PluginSpec.Action(StateActions.Volume.DownloadDensity),
    PluginSpec.Action(StateActions.DataFormat.DownloadFile),
    PluginSpec.Action(StateActions.DataFormat.OpenFiles),
    PluginSpec.Action(StateActions.Structure.LoadTrajectory),
    PluginSpec.Action(StateActions.Structure.EnableModelCustomProps),
    PluginSpec.Action(StateActions.Structure.EnableStructureCustomProps),

    // Volume streaming
    PluginSpec.Action(InitVolumeStreaming),
    PluginSpec.Action(BoxifyVolumeStreaming),
    PluginSpec.Action(CreateVolumeStreamingBehavior),

    //PluginSpec.Action(StateTransforms.Data.Download),
    PluginSpec.Action(StateTransforms.Data.ParseCif),
    PluginSpec.Action(StateTransforms.Data.ParseCcp4),
    PluginSpec.Action(StateTransforms.Data.ParseDsn6),

    PluginSpec.Action(StateTransforms.Model.TrajectoryFromMmCif),
    PluginSpec.Action(StateTransforms.Model.TrajectoryFromCifCore),
    PluginSpec.Action(StateTransforms.Model.TrajectoryFromPDB),
    PluginSpec.Action(StateTransforms.Model.TransformStructureConformation),
    PluginSpec.Action(StateTransforms.Model.StructureFromModel),
    PluginSpec.Action(StateTransforms.Model.StructureFromTrajectory),
    PluginSpec.Action(StateTransforms.Model.ModelFromTrajectory),
    PluginSpec.Action(StateTransforms.Model.StructureSelectionFromScript),
    PluginSpec.Action(StateTransforms.Representation.StructureRepresentation3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsDistance3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsAngle3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsDihedral3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsLabel3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsOrientation3D),
    PluginSpec.Action(StateTransforms.Representation.ModelUnitcell3D),
    PluginSpec.Action(StateTransforms.Representation.StructureBoundingBox3D),
    PluginSpec.Action(StateTransforms.Representation.ExplodeStructureRepresentation3D),
    PluginSpec.Action(StateTransforms.Representation.SpinStructureRepresentation3D),
    PluginSpec.Action(StateTransforms.Representation.UnwindStructureAssemblyRepresentation3D),
    PluginSpec.Action(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript),
    PluginSpec.Action(StateTransforms.Representation.TransparencyStructureRepresentation3DFromScript),
    PluginSpec.Action(StateTransforms.Representation.ClippingStructureRepresentation3DFromScript),
    PluginSpec.Action(StateTransforms.Representation.SubstanceStructureRepresentation3DFromScript),
    PluginSpec.Action(StateTransforms.Representation.ThemeStrengthRepresentation3D),

    PluginSpec.Action(AssignColorVolume),
    PluginSpec.Action(StateTransforms.Volume.VolumeFromCcp4),
    PluginSpec.Action(StateTransforms.Volume.VolumeFromDsn6),
    PluginSpec.Action(StateTransforms.Volume.VolumeFromCube),
    PluginSpec.Action(StateTransforms.Volume.VolumeFromDx),
    PluginSpec.Action(StateTransforms.Representation.VolumeRepresentation3D),
  ],
  behaviors: [
    PluginSpec.Behavior(PluginBehaviors.Representation.HighlightLoci),
    PluginSpec.Behavior(PluginBehaviors.Representation.SelectLoci),
    PluginSpec.Behavior(PluginBehaviors.Representation.DefaultLociLabelProvider),
    PluginSpec.Behavior(PluginBehaviors.Representation.FocusLoci),
    PluginSpec.Behavior(PluginBehaviors.Camera.FocusLoci),
    PluginSpec.Behavior(PluginBehaviors.Camera.CameraAxisHelper),
    PluginSpec.Behavior(PluginBehaviors.Camera.CameraControls),
    PluginSpec.Behavior(StructureFocusRepresentation),

    PluginSpec.Behavior(PluginBehaviors.CustomProps.StructureInfo),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.AccessibleSurfaceArea),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.BestDatabaseSequenceMapping),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.Interactions),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.SecondaryStructure),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.ValenceModel),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.CrossLinkRestraint),
  ],
  animations: [
    AnimateModelIndex,
    AnimateCameraSpin,
    AnimateCameraRock,
    AnimateStateSnapshots,
    AnimateAssemblyUnwind,
    AnimateStructureSpin,
    AnimateStateInterpolation
  ]
});

const loadStructure = async (data, label: string, contentType: string, plugin, clear) => {
  if (plugin) {
    if (clear) {
      plugin.clear();
    }
    if (data) {
      const rawData = await plugin.builders.data.rawData({
        label,
        data,
      });
      const traj = await plugin.builders.structure.parseTrajectory(rawData, contentType as BuiltInTrajectoryFormat);
      await plugin.builders.structure.hierarchy.applyPreset(traj, 'default');
    }

  }
}

const LoadMoreFiles = ({fileList, molPlugin}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [supportedFiles, setSupportedFiles] = useState([])
  const [checked, setChecked] = useState([])

  const [areFilesLoading, setAreFilesLoading] = useState(false);

  const handleToggle = (path: string) => () => {
    const currentIndex = checked.indexOf(path);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(path);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (fileList) {
      const newSupportedFiles = fileList.filter((f) => isSupportedByMolstar(getFileExtension(f.name)));
      setSupportedFiles(newSupportedFiles);
    }
  }, [fileList])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setChecked([]);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <>
    <LoadingButton aria-describedby={id} onClick={handleClick} sx={{display: molPlugin ? 'initial' : 'hidden'}}
                   disabled={areFilesLoading} loading={areFilesLoading}>
      Load more...
    </LoadingButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {supportedFiles.length > 0 ?
        <Stack sx={{p: 1}}>
          <Typography sx={{p: 2}}>Select file to add</Typography>
          <List sx={{
            width: '100%',
            maxHeight: 300,
            overflowY: 'auto',
            minWidth: 360,
            maxWidth: 600,
            bgcolor: 'background.paper'
          }}>
            {supportedFiles.map((file) => {
              const labelId = `checkbox-list-label-${file.name}`;

              return (
                <ListItem
                  key={file.name}
                  disablePadding
                  disableGutters
                >
                  <ListItemButton role={undefined}  sx={{ height: 48 }} onClick={handleToggle(file.name)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.includes(file.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={getFileName(file.name)} secondary={getFileFolder(file.name)}/>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <LoadingButton loading={areFilesLoading} disabled={areFilesLoading} onClick={async () => {
            setAreFilesLoading(true)
            try {
              if (molPlugin && checked.length > 0) {
                for (let i = 0; i < checked.length; i++) {
                  const file = fileList.find(f => f.name === checked[i]);
                  const content = await loadTextFile(file.name, file.dataset_id);
                  await loadStructure(content, file.name, getMolstarContentType(getFileExtension(file.name)), molPlugin, false);
                }
                handleClose()
              }
            } catch (e) {
              console.log(e);
            } finally {
              setAreFilesLoading(false)

            }
          }}>Load</LoadingButton>
        </Stack> : <Typography sx={{p: 2}}>How is it possible? </Typography>}
    </Popover>
  </>
}
export const MolstarViewer = (props: MolstarViewerProps) => {
  const {data, contentType, label, fileList} = props;
  const parentRef = useRef(null);
  const plugin = useRef(null);
  const [initialized, setInitialized] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const partialExamples = examples.slice(0, 10);

  const queryForm = useFormik({
    initialValues: {
      query: '',
    },

    onSubmit: values => {
      const queryExpression = parse('pymol', values.query);
      const sq = StructureSelectionQuery('blub', queryExpression);
      plugin.current.managers.structure.selection.fromSelectionQuery('set', sq);
    },
  });

  useEffect(() => {
    const init = async () => {
      const spec = CustomPluginSpec();
      spec.layout = {
        initial: {
          isExpanded: false,
          controlsDisplay: 'reactive',
          showControls: false,
          // remoteState: 'none',
        },
      };
      spec.components = {
        remoteState: 'none'
      }
      plugin.current = await createPluginUI(parentRef.current, spec);
      await loadStructure(data, label, contentType, plugin.current, true);

      setInitialized(true);
    };

    init();

    return () => {
      plugin.current = null;
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;
    (async () => {
      await loadStructure(data, label, contentType, plugin.current, true);
    })();
  }, [data]);

  const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setAnchorEl(null);
  };


  return (
    <Stack direction={"column"}
           style={{
             position: 'relative',
             paddingTop: 8,
             flex: 1,
             alignItems: "center",
             left: 0,
             top: 0,
             right: 0,
             bottom: 0
           }}>
      <Stack>
        <form onSubmit={queryForm.handleSubmit}>
          <Stack spacing={2} direction={"row"}>
            <FormControl sx={{minWidth: 600}} variant="outlined">
              <InputLabel htmlFor="pymolQuery" size={"small"}>PyMOL command</InputLabel>
              <OutlinedInput
                endAdornment={<InputAdornment position="end">
                  <IconButton size={"small"} onClick={handleHelpClick}><HelpOutlineOutlined/></IconButton>
                  <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleHelpClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Stack sx={{p: 2}} spacing={1}>
                      <Typography variant={"body1"}>The viewer partially supports PyMOL
                        commands.</Typography><Typography variant={"body1"}>Examples:</Typography>
                      {partialExamples.map(example => <Stack direction={"row"} spacing={2} sx={
                        {alignItems: "center"}
                      }>
                        <Typography variant={"body2"}>{example.name}</Typography>
                        <Stack
                          spacing={1}
                          direction={"row"}
                          sx={{
                            fontFamily: 'monospace',
                            fontSize: 12,
                            p: 0.5,
                            backgroundColor: '#f6f6f6',
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            queryForm.setFieldValue('query', example.value)
                            handleHelpClose();
                            queryForm.submitForm();
                          }}
                        >{example.value}<Launch sx={{ml: 1, fontSize: 14}}/></Stack>
                      </Stack>)}
                    </Stack>
                  </Popover>
                </InputAdornment>}
                aria-describedby="pymolQuery"
                inputProps={{
                  'aria-label': 'PyMOL command',
                }}
                id="pymolQuery"
                label="PyMOL command"
                size={"small"}
                sx={{minWidth: 600}}
                name="query"
                value={queryForm.values.query}
                onChange={queryForm.handleChange}
              />

            </FormControl>
            <Button
              variant="contained"
              color="primary"

              onClick={() => {
                queryForm.handleSubmit();
              }}
            >
              {'run'}
            </Button>
            <LoadMoreFiles fileList={fileList} molPlugin={plugin.current}/>
          </Stack>
        </form>
      </Stack>
      <div ref={parentRef} style={{position: 'absolute', top: 60, right: 0, bottom: 0, left: 0}}/>
    </Stack>
  );
};

export default MolstarViewer;
