import {Apps, PageFooter, PageHeader} from '@biostrand/components';
import {SettingsIds} from '@biostrand/components/src/applications/SettingsIds';
import {currentUserSelector} from '@biostrand/components/src/slices/user/userSelectors';
import {Stack} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {OrganizationRole} from '../../../core/organization/organizationTypes';
import {isFeatureSupported, PortalRuntimeFeatures} from '../../../portalRuntimeConfig';
import {RouteUrl} from '../../../routeUrl';
import SettingsMenu from './settingsMenu/SettingsMenu';
import {GroupedMenuItems} from './settingsMenu/settingsMenuTypes';

interface Props {
    children: JSX.Element | string;
    selectedItem: SettingsIds | Apps;
}

const menuGroups: GroupedMenuItems[] = [
    {
        label: 'General settings',
        items: [
            {
                link: RouteUrl.PersonalSettings,
                label: 'Personal',
                id: SettingsIds.PERSONAL,
            },
            {
                link: RouteUrl.OrganizationSettings,
                label: 'Organization',
                id: SettingsIds.ORGANIZATION,
                adminOnly: true,
            },
            {
                link: RouteUrl.BillingSettings,
                label: 'Billing',
                id: SettingsIds.BILLING,
                adminOnly: true,
            },
        ],
    },
    {
        label: 'Management',
        items: [
            {
                link: RouteUrl.UsersSettings,
                label: 'Users',
                id: SettingsIds.USERS,
                adminOnly: true,
            },
            {
                link: RouteUrl.DataSetsSettings,
                label: 'Datasets',
                id: SettingsIds.DATASETS,
            },
        ],
    },
];

if (isFeatureSupported(PortalRuntimeFeatures.JOBS)) {
    menuGroups[1].items.push({
        link: RouteUrl.JobsSettings,
        label: 'Jobs',
        id: SettingsIds.JOBS,
    });
}

const SettingsPageTemplate = (props: Props): JSX.Element => {
    const { selectedItem, children } = props;
    const currentUser = useSelector(currentUserSelector);
    const [userMenu, setUserMenu] = useState<GroupedMenuItems[]>([]);

    useEffect(() => {
        if (currentUser) {
            const isAdmin = currentUser.organization_role === OrganizationRole.ADMIN;
            const um = menuGroups
                .map(mg => {
                    const uis = mg.items.filter(item =>  item.adminOnly ? isAdmin : true);
                    return {
                        ...mg,
                        items: uis,
                    };
                })
                .filter(um => um.items.length > 0);

            setUserMenu(um);
        } else {
            setUserMenu([]);
        }
    }, [currentUser]);

    return (
        <Stack sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <PageHeader
                selectedAppId={Apps.SETTINGS}
                sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100, boxShadow: 1 }}
            />
            <Stack direction={"row"} sx={{ mb: 8, overflowY: 'hidden', flex:1 }}>
                <Stack sx={{ minWidth: theme => theme.spacing(25), boxShadow: 3, p: 3 }}>
                    <SettingsMenu menuItems={userMenu} selectedItem={selectedItem} />
                </Stack>
                <Stack
                    id="settingsPageTemplateContent"
                    sx={{
                        flex:1,
                        p:0.2,
                        minWidth: theme => theme.spacing(25),
                        overflow: 'hidden',

                    }}>
                    {children}
                </Stack>
            </Stack>
            <PageFooter sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, boxShadow: 1 }} />
        </Stack>
    );
};

export default SettingsPageTemplate;
