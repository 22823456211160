import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  FileFinalizeErrorPayload,
  FilePartStatus,
  FileResourcePayload,
  FileUploadInfo,
  FileUploadManagerState,
  FileUploadManagerStatus,
  FileUploadPopupState,
  PartUploadErrorPayload,
  UploadCompletePayload,
  UploadProgressPayload,
} from './fileUploadTypes';


import groupBy from "lodash.groupby";
import {getFileStatusSummary} from "./fileUploadUtils";

export const INIT_FILE_UPLOAD_MANAGER = '@fum/INIT_FILE_UPLOAD_MANAGER';
export const UPLOAD_MANAGER_SAVE_STATE = '@fum/UPLOAD_MANAGER_SAVE_STATE';
export const KICK_UPLOAD_PROCESS = '@fum/KICK_UPLOAD_PROCESS';

export const UPLOAD_PROGRESS = '@fum/UPLOAD_PROGRESS';
export const UPLOAD_CANCELLED = '@fum/UPLOAD_CANCELLED';
export const PART_IS_READY = '@fum/PART_IS_READY';
export const PART_ERROR = '@fum/PART_ERROR';

export const CANCEL_FILE_UPLOADING = '@fum/CANCEL_FILE_UPLOADING';
export const FINALIZE_DATASET = '@fum/FINALIZE_DATASET';

export const initFileUploadManager = createAction(INIT_FILE_UPLOAD_MANAGER);
export const uploadManagerSaveState = createAction(UPLOAD_MANAGER_SAVE_STATE);
export const kickUploadProcess = createAction(KICK_UPLOAD_PROCESS);

export const cancelFileUploading = createAction<string>(CANCEL_FILE_UPLOADING);
export const finalizeDatasetAction = createAction<string>(FINALIZE_DATASET);

const initialState: FileUploadManagerState = {
  managerStatus: FileUploadManagerStatus.PENDING,
  currentFiles: [],
  filesByDatasetId: {},
  popupState: FileUploadPopupState.HIDDEN,
};

export const fileUploadManagerSlice = createSlice({
  name: 'fileUploadManager',
  initialState,
  reducers: {
    setCurrentFiles: (state, action: PayloadAction<FileUploadInfo[]>) => {
      state.currentFiles = action.payload;
      state.filesByDatasetId = groupBy(action.payload, 'context.dataset.id');
      if (action.payload && action.payload.length > 0 && state.popupState !== FileUploadPopupState.OPEN) {
        state.popupState = FileUploadPopupState.OPEN;
      }

    },
    addFile: (state, action: PayloadAction<FileUploadInfo>) => {
      const fileInfo = {...action.payload, last_updated: new Date().getTime()}
      const files = [...state.currentFiles, fileInfo]
      state.currentFiles = files;
      state.filesByDatasetId = groupBy(files, 'context.dataset.id');
      state.popupState =  FileUploadPopupState.OPEN;
    },

    removeFile: (state, action: PayloadAction<string>) => {
      const fileId = action.payload;
      const files = state.currentFiles.filter(fs => fileId !== fs.fileId);
      state.currentFiles = files;
      state.filesByDatasetId = groupBy(files, 'context.dataset.id');
    },
//fixme
    removeDataset: (state, action: PayloadAction<string>) => {
      const datasetId = action.payload;
      state.currentFiles = state.currentFiles.filter(fs => datasetId !== fs.context.dataset.id);
    },
    setFileManagerBusy: state => {
      state.managerStatus = FileUploadManagerStatus.BUSY;
    },
    setFileManagerPending: state => {
      state.managerStatus = FileUploadManagerStatus.PENDING;
    },
    partUploadProgress: (state, action: PayloadAction<UploadProgressPayload>) => {
      const { fileStatus, filePart, progress } = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileStatus.fileId);
      if (fileStatusToChange) {
        const filePartToChange = fileStatusToChange.parts.find(fp => fp.index === filePart.index);

        if (filePartToChange) {
          filePartToChange.progress = progress;
          fileStatusToChange.last_updated = new Date().getTime();
        }
      }
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },
    partUploaded: (state, action: PayloadAction<UploadCompletePayload>) => {
      const { fileStatus, filePart, ETag } = action.payload;

      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileStatus.fileId);
      if (fileStatusToChange) {
        const filePartToChange = fileStatusToChange.parts.find(fp => fp.index === filePart.index);

        if (filePartToChange) {
          filePartToChange.ETag = ETag;
          filePartToChange.status = FilePartStatus.READY;
          fileStatusToChange.last_updated = new Date().getTime();
        }
      }
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },
    partUploadError: (state, action: PayloadAction<PartUploadErrorPayload>) => {
      const { fileStatus, filePart } = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileStatus.fileId);
      if (fileStatusToChange) {
        const filePartToChange = fileStatusToChange.parts.find(fp => fp.index === filePart.index);

        if (filePartToChange) {
          filePartToChange.status = FilePartStatus.ERROR;
          fileStatusToChange.last_updated = new Date().getTime();
        }
      }
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },

    clearPartUploadError: (state, action: PayloadAction<string>) => {
      const fileId = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);

      if (fileStatusToChange) {
        fileStatusToChange.parts.forEach(fp => {
          if (fp.status === FilePartStatus.ERROR) {
            fp.status = FilePartStatus.DRAFT;
          }
        });
        fileStatusToChange.last_updated = new Date().getTime();
      }
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },

    clearAllPartUploadErrors: (state, action: PayloadAction<string>) => {
      const datasetId = action.payload;
      state.currentFiles.forEach( fileStatusToChange => {
        if (fileStatusToChange.context.dataset?.id === datasetId) {
          fileStatusToChange.parts.forEach(fp => {
            if (fp.status === FilePartStatus.ERROR) {
              fp.status = FilePartStatus.DRAFT;
            }
          });
          fileStatusToChange.last_updated = new Date().getTime();
        }
      })
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },

    keepOnlyReadyFiles: (state, action: PayloadAction<string>) => {
      const datasetId = action.payload;
      state.currentFiles = state.currentFiles.filter( fileStatus => {
        if (datasetId !== fileStatus.context?.dataset?.id) return true;
          const summary = getFileStatusSummary(fileStatus);
        return summary.isReady;
        }
      )

      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },


    abortFileUpload: (state, action: PayloadAction<string>) => {
      const fileId = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);
      if (fileStatusToChange) {
        fileStatusToChange.isAborted = true;
        fileStatusToChange.last_updated = new Date().getTime();
      }
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },
    setFileStatus: (state, action: PayloadAction<FileFinalizeErrorPayload>) => {
      const { fileId, status } = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);
      if (fileStatusToChange) {
        fileStatusToChange.status = status;
        fileStatusToChange.last_updated = new Date().getTime();
      }
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },
    assignFileResource: (state, action: PayloadAction<FileResourcePayload>) => {
      const { fileId, file } = action.payload;
      const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);
      if (fileStatusToChange) {
        fileStatusToChange.file = file;
      }
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },
    assignMultipleFileResource: (state, action: PayloadAction<FileResourcePayload[]>) => {
      const files = action.payload;

      files.forEach(  ({ fileId, file }) => {
        const fileStatusToChange = state.currentFiles.find(fus => fus.fileId === fileId);
        if (fileStatusToChange) {
          fileStatusToChange.file = file;
        }
      });
      state.filesByDatasetId = groupBy(state.currentFiles, 'context.dataset.id');
    },
    setFileUploadPopupState: (state, action:PayloadAction<FileUploadPopupState>) => {
      state.popupState = action.payload;
    }
  },
});

export const {
  setCurrentFiles,
  addFile,
  setFileManagerBusy,
  setFileManagerPending,
  removeFile,
  partUploadProgress,
  partUploaded,
  keepOnlyReadyFiles,
  removeDataset,
  partUploadError,
  abortFileUpload,
  setFileStatus,
  assignFileResource,
  clearPartUploadError,
  clearAllPartUploadErrors,
  setFileUploadPopupState,
  assignMultipleFileResource
} = fileUploadManagerSlice.actions;

export default fileUploadManagerSlice.reducer;
