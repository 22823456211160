import {IconButton, LinearProgress, Stack, Tooltip, Typography} from '@mui/material';
import {DatasetManagerDatasetItem} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {
  createBindersGridColumn,
  findPDBFileName,
  isPDBResultsColumn,
  parseBinderResults,
  PDB_RESULTS_COLUMNS,
  PINNED_RESULTS_COLUMNS
} from "./ngsUtils";
import {
  DataGridPro,
  GridCellEditStopReasons,
  GridCsvExportMenuItem,
  GridRowId,
  GridRowModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
import {ErrorPanel} from "../../ErrorPanel/ErrorPanel";
import {GridActionsCellItem, GridRenderCellParams} from "@mui/x-data-grid";
import {AddComment, ArrowBack, Close, VerticalAlignTop, Visibility} from "@mui/icons-material";
import {closePopup, showPopup} from "../../popup/popupsSlice";
import {FilePreviewPopup} from "../fileViewer/FilePreviewPopup";
import {useDispatch} from "react-redux";
import {NGXToolbarGridAlignSequence} from "./NGXToolbarGridAlignSequence";
import {
  persistParsedToLocalStorage,
  retrieveParsedFromLocalStorage
} from "@biostrand/articlelens/src/app/utils/localStorageHelpers";
import {EditTextarea} from "./EditTextarea";
import {loadTextFile} from '../fileViewer/loadFile';

interface Props {
  binderInfo: any,
  datasetId: string,
  binderResultPath: string,
  popupKey: string;
  dsFiles: DatasetManagerDatasetItem[] | undefined,
}

const GridToolbarExport = ({csvOptions = undefined, ...other}) => (
  <GridToolbarExportContainer {...other}>
    <GridCsvExportMenuItem options={csvOptions}/>
  </GridToolbarExportContainer>
);

const CustomToolbar = (props) => {
  const {csvOptions} = props;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton/>
      <GridToolbarFilterButton/>
      <GridToolbarExport csvOptions={csvOptions}/>
      <div style={{width: 40}}></div>
      <NGXToolbarGridAlignSequence/>
    </GridToolbarContainer>
  );
}

function isKeyboardEvent(event: any): event is React.KeyboardEvent {
  return !!event.key;
}

export const NGSResultOverviewPopup = (props: Props): JSX.Element => {
  const {binderInfo, datasetId, binderResultPath, popupKey, dsFiles} = props;
  const [noResults, setNoResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSelection, setCurrentSelection] = useState()

  const [data, setData] = useState();

  const [columnNames, setColumnNames] = useState();
  const [pinnedRowIds, setPinnedRowIds] = useState<{
    top: GridRowId[];
  }>({
    top: [],
  });

  const dispatch = useDispatch();

  const fillComments = (rows: []) => {
    const currentComments = retrieveParsedFromLocalStorage('csvComments') || {};
    rows.forEach(row => {
      const cKey = `${datasetId}::${binderResultPath}::${row.id}`;
      row.comment = currentComments[cKey];
    })
  }

  const {rows, pinnedRows} = React.useMemo(() => {
    const rowsData: GridRowModel[] = [];
    const pinnedRowsData: { top: GridRowModel[] } = {
      top: [],
    };

    data && data.forEach((row) => {
      if (pinnedRowIds.top.includes(row.id)) {
        pinnedRowsData.top.push(row);
      } else {
        rowsData.push(row);
      }
    });

    return {
      rows: rowsData,
      pinnedRows: pinnedRowsData,
    };


  }, [data, pinnedRowIds, columnNames]);


  const onSaveComment = async (updatedRow, originalRow) => {
    const currentComments = retrieveParsedFromLocalStorage('csvComments') || {};
    const cKey = `${datasetId}::${binderResultPath}::${updatedRow.id}`;
    currentComments[cKey] = updatedRow.comment;
    persistParsedToLocalStorage('csvComments', currentComments);
    return Promise.resolve(updatedRow);
  }

  const onFilePreview = (fileName: string) => {
    dispatch(
      showPopup({
        fullscreen: true,
        key: 'file-preview-popup-key',
        title: (
          <Stack direction={'row'} sx={{mt: 2, alignItems: 'center'}} spacing={4}>
            <IconButton
              onClick={() => {
                dispatch(closePopup('file-preview-popup-key'));
              }}
            >
              <ArrowBack/>
            </IconButton>
            {'Preview'}
          </Stack>
        ),
        content: React.createElement(FilePreviewPopup, {
          popupKey: 'file-preview-popup-key',
          datasetId: datasetId,
          filePath: fileName,
        }),
      }),
    );
  };

  const gridColumns = useMemo(() => {
    if (columnNames) {
      const newColumns = columnNames.map(createBindersGridColumn)
      newColumns.forEach(column => {
        if (isPDBResultsColumn(column.field)) {
          column.renderCell = (params: GridRenderCellParams<string>) => {
            return <Stack sx={{alignItems: 'center', height: 28}} direction={"row"}>{
              (params.row[`${column.field}_pdbFile`]) ? <IconButton
                onClick={() => onFilePreview(params.row[`${column.field}_pdbFile`])}><Visibility/></IconButton> : null
            }
              <Typography variant={"body2"}>{params.row[column.field]}</Typography>
            </Stack>;
          }
        }
      })

      newColumns.unshift(
        {
          field: 'cActions',
          type: 'actions',
          sortable: true,
          width: 50,
          getActions: (params) => {

            const isReference = pinnedRowIds.top.includes(params.row.id);

            if (isReference) {
              return [
                <GridActionsCellItem
                  label="Unpin"
                  icon={
                    <Tooltip title="Remove reference">
                      <Close/>
                    </Tooltip>
                  }
                  onClick={() => setPinnedRowIds({top: []})}
                />
              ];
            }
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Use row as reference">
                    <VerticalAlignTop/>
                  </Tooltip>
                }
                label="Pin at the top"
                onClick={() => {
                  setPinnedRowIds({
                    top: [params.row.id]
                  })
                }
                }
              />
            ];
          },
        }
      )

      newColumns.push(
        {
          field: "comment",
          headerName: 'Comment',
          sortable: true,
          minWidth: 100,
          editable: true,
          flex: 1,
          valueFormatter: (value) => value ? value : '',
          renderEditCell: (params) => <EditTextarea {...params} />,
          renderCell: (params: GridRenderCellParams<string>) => {
            return <Stack title={params.row.comment} sx={{
              height: '100%',
              alignContent: 'flex-start',
              justifyContent: "center",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
              {params.row['comment']
                ? params.row.comment
                : <Stack direction={"row"}>
                  <div style={{flex: 1}}/>
                  <AddComment color={'disabled'}/>
                </Stack>
              }
            </Stack>
          },
        })
      return newColumns;
    }

    return [];
  }, [data, columnNames, pinnedRowIds])

  useEffect(() => {
    const loadResults = async (binderResultPath: string) => {
      if (binderResultPath) {
        try {
          setIsLoading(true);
          const fileContent = await loadTextFile(binderResultPath, datasetId);
          if (fileContent) {
            let bindersInfo = parseBinderResults(fileContent);

            const rows = bindersInfo.rows;

            fillComments(rows)
            rows.forEach(row => {
              PDB_RESULTS_COLUMNS.forEach(colName => {
                if (row[colName]) {
                  row[`${colName}_pdbFile`] = findPDBFileName(dsFiles, row[colName]);
                }
              })
            });

            setData(rows);
            setColumnNames(bindersInfo.columns);
          } else {
            setNoResults(true);
          }
          setIsLoading(false);
        } catch (e) {
          console.log(e);
        }
      } else {
        setNoResults(true);
      }
    }
    loadResults(binderResultPath);

  }, [binderResultPath])


  if (isLoading)
    return (
      <Stack sx={{minHeight: 300, overflowY: 'hidden', minWidth: 300, flex: 1}}>
        <Typography>
          <LinearProgress/>
        </Typography>
      </Stack>
    );

  if (noResults) {
    return (
      <Stack sx={{minHeight: 300, overflowY: 'hidden', minWidth: 700, flex: 1}}>
        <Stack sx={{alignItems: 'center', justifyContent: 'flex-start', minWidth: 700, flex: 1}}>
          <ErrorPanel title={'No related results'} subtitle={''}/>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction={'column'}
      sx={{flex: 1, pl: 3, pb: 1, position: 'relative'}}>
      <Stack sx={{top: 0, bottom: 0, left: 0, right: 0, position: 'absolute'}}>

        {rows &&
        <>
          <DataGridPro
            rowHeight={28}
            loading={isLoading}
            checkboxSelection
            keepNonExistentRowsSelected
            disableRowSelectionOnClick
            pinnedRows={pinnedRows}
            initialState={{pinnedColumns: {left: PINNED_RESULTS_COLUMNS, right: ['comment']}}}
            columns={gridColumns}
            rows={rows}
            processRowUpdate={onSaveComment}
            onCellEditStop={(params, event) => {
              if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
                return;
              }
              if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
                event.defaultMuiPrevented = true;
              }
            }}
            onRowSelectionModelChange={
              (model) => {
                setCurrentSelection(model)
              }
            }
            slotProps={{
              toolbar: {
                csvOptions: {fileName: `${binderInfo.id}_hits.csv`}
              },
              columns: gridColumns,
              selectedItems: currentSelection

            }}
            slots={{toolbar: CustomToolbar}}

          /></>}
      </Stack>
    </Stack>
  )
}

