import {
    DataFilter,
    DateFilterValue,
    EnumFilterValue,
    FieldFilterType,
    FilterType,
    NumberFilterValue,
    StringFilterValue
} from "../../filters/dataFiltersTypes";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dataFilterSelector} from "../../filters/dataFiltersSelectors";
import {updateDataFilter} from "../../filters/dataFiltersSlice";
import dayjs from "dayjs";

export const applyDateFilter = (item: any, filter: DataFilter): boolean => {
    const {field, value} = filter;
    const dateFilter = value as DateFilterValue;
    let match = true;
    const itemValue = new Date(item[field])

    for (const key in dateFilter) {
        if (dateFilter[key as keyof DateFilterValue]) {
            const filterValue = dateFilter[key as keyof DateFilterValue]!;
            switch (key) {

                case 'before':
                    match = match && itemValue >= filterValue;
                    break;
                case 'after':
                    match = match && itemValue <= filterValue;
                    break;
                case 'between':
                    match = match && dayjs(itemValue).isAfter(dayjs(filterValue[0])) && dayjs(itemValue).isBefore(dayjs(filterValue[1]));
                    break;
            }
        }
    }
    return match;
}

export const applyNumberFilter = (item: any, filter: DataFilter): boolean => {
    const {field, value} = filter;
    const numberFilter = value as NumberFilterValue
    let match = true;
    const itemValue = item[field]

    for (const key in numberFilter) {
        if (numberFilter[key as keyof NumberFilterValue]) {
            const filterValue = numberFilter[key as keyof NumberFilterValue]!;
            switch (key) {
                case 'eq':
                    match = match && itemValue === filterValue;
                    break;
                case 'gt':
                    match = match && itemValue > filterValue;
                    break;
                case 'lt':
                    match = match && itemValue < filterValue;
                    break;
                case 'ge':
                    match = match && itemValue >= filterValue;
                    break;
                case 'le':
                    match = match && itemValue <= filterValue;
                    break;
                case 'between':
                    match = match && itemValue >= filterValue[0] && itemValue <= filterValue[1];
                    break;
            }
        }
    }
    return match;
}

const applyEnumFilter = (item: any, filter: DataFilter) => {
    const {field, value} = filter;
    const enumFilter = value as EnumFilterValue
    const itemValue = item[field];

    if (enumFilter.eq) {
        return itemValue === enumFilter.eq;
    }

    if (enumFilter.$in) {
        return enumFilter.$in.includes(itemValue);
    }

    if (enumFilter.nin) {
        return !enumFilter.nin.includes(itemValue);
    }
    return true; //don't apply empty filter;
}

export const applyStringFilter = (item: any, filter: DataFilter): boolean => {
    const {field, value} = filter;
    const valueToTest = item[field].toLowerCase();
    const stringFilterValue = value as StringFilterValue;

    if (valueToTest) {
        let match = true;
        for (const key in stringFilterValue) {
            const filterValue = stringFilterValue[key as keyof StringFilterValue].toLowerCase();
            if (stringFilterValue[key as keyof StringFilterValue]) {
                switch (key) {
                    case 'eq':
                        match = match && valueToTest === filterValue;
                        break;
                    case 'like':
                        match = match && valueToTest.includes(filterValue.replace(/\*/g, ''));
                        break;
                    case 'startsWith':
                        match = match && valueToTest.startsWith(filterValue);
                        break;
                    case 'endsWith':
                        match = match && valueToTest.endsWith(filterValue);
                        break;
                    case 'contains':
                        match = match && valueToTest.includes(filterValue);
                        break;
                    case 'regex':
                        const regex = new RegExp(filterValue);
                        match = match && regex.test(valueToTest);
                        break;
                }
            }
        }
        return match;
    }
}

export const applyFilters = (source: any[], filters: DataFilter[]): any[] => {
    let result = source;
    for (let i = 0; i < filters.length; i++) {
        const currentFilter: DataFilter = filters[i];
        result = result.filter((ds) => {
            if (currentFilter.type === FilterType.FIELD && currentFilter.fieldType) {
                switch (currentFilter.fieldType) {
                    case FieldFilterType.DATE:
                        return applyDateFilter(ds, currentFilter);
                    case FieldFilterType.NUMBER:
                        return applyNumberFilter(ds, currentFilter);
                    case FieldFilterType.STRING:
                        return applyStringFilter(ds, currentFilter);

                    case FieldFilterType.ENUM: {
                        return applyEnumFilter(ds, currentFilter);
                    }
                }
            }
            if (currentFilter.type === FilterType.TEST_FUNCTION && currentFilter.testFunction) {
                return currentFilter.testFunction(ds);
            }
            return false;
        });
    }
    return result;
}

export const useDataFilter = <T>(filterKey: string, source: T[], defaultFilters?: DataFilter[]): T[] => {
    const currentFilters = useSelector(dataFilterSelector(filterKey));

    const [filteredDatasets, setFilteredDatasets] = useState();
    const dispatch = useDispatch();

    useEffect(()=> {
        if (defaultFilters) {
            dispatch(updateDataFilter({key: filterKey, filters: defaultFilters}));
        }
    }, [])

    useEffect(() => {
        if (!currentFilters || !source) {
            setFilteredDatasets(source);
            return;
        }
        const filteredDs = applyFilters(source, currentFilters);
        setFilteredDatasets(filteredDs);
    }, [source, currentFilters])

    return [filteredDatasets];
}