import {DataFilter, FieldFilter, FieldFilterType, FilterType} from "./dataFiltersTypes";
import {GridSortModel} from "@mui/x-data-grid";

export interface IRQLExpression {
    $eq?: string | number;
    $ne?: string | number;
    $not?: IRQLExpression;
    $gt?: number;
    $ge?: number;
    $lt?: number;
    $le?: number;
    $like?: string;
    $ilike?: string;
    $in?: Array<number | string>;
    $out?: Array<number | string>;
    $range?: {
        min: number;
        max: number;
    };
}

export interface IRQL {
    $and?: Array<IRQL>;
    $or?: Array<IRQL>;
    $ordering?: Array<string>;
    $select?: Array<string>;
    limit?: number;
    offset?: number;
    [key: string]: string | number | Array<string | number> | boolean | IRQLExpression | Array<IRQL> | undefined;
}

export const mapFiltersToIRQL = (filters: DataFilter[], prefix:string = ''): IRQL => {
    const irql: IRQL = { $and: [] };
    filters.forEach(filter => {
        if (filter.type === FilterType.FIELD) {
            const fieldFilter = filter as FieldFilter;
            const expression: IRQLExpression = {};

            switch (fieldFilter.fieldType) {
                case FieldFilterType.ENUM:
                case FieldFilterType.STRING:
                    if (fieldFilter.value['$in']) {
                        let list = fieldFilter.value['$in'] as Array<string | number>;

                        if (list.length > 1) {
                            const listItems = list.map(value => ({[`${prefix}${fieldFilter.field}`]: {$eq: value}}))
                            irql.$and?.push({'$or': listItems});
                        } else {
                            irql.$and?.push({ [`${prefix}${fieldFilter.field}`]:{$eq:  list[0]}});
                        }
                    }
                    if (fieldFilter.value['$eq']) {
                        irql.$and?.push({ [`${prefix}${fieldFilter.field}`]:{$eq:  fieldFilter.value['$eq']}});
                    }
                    break;
                case FieldFilterType.DATE:
                    if ('between' in fieldFilter.value) {
                        //debugger;
                        expression.$gt = (fieldFilter.value.between[0].startOf('day') )
                        expression.$lt = (fieldFilter.value.between[1].endOf('day'))
                    } else {
                        // todo add gt/lt case

                    }
                    break;
                case FieldFilterType.NUMBER:
                    // todo implement and add gt/lt case (there are no filters on number values yet )
                    if ('between' in fieldFilter.value) {
                        expression.$gt = fieldFilter.value.between[0];
                        expression.$lt = fieldFilter.value.between[1];
                    } else {

                    }
                    break;
            }

            if (Object.keys(expression).length > 0) {
                irql.$and?.push({ [`${prefix}${fieldFilter.field}`]: expression });
            }
        }
    });

    return irql;
}

export const getRqlQueryString = (prefix:string , filters?: DataFilter[], sortModel?: GridSortModel) => {
    const query = {}
    if (sortModel && sortModel.length) {
        query.sort = sortModel.map( sortItem => sortItem.sort === 'asc' ? `${prefix}${sortItem.field}` : `-${prefix}${sortItem.field}`)
    }
    if (filters) {
        query.filter = mapFiltersToIRQL(filters,prefix)
    }

    console.log('JSON.stringify(query) => ', JSON.stringify(query))
    return JSON.stringify(query);
}
