import {useEffect, useState} from "react";
import {DataFilter,} from "../filters/dataFiltersTypes";
import {useSelector} from "react-redux";
import {dataFilterSelector} from "../filters/dataFiltersSelectors";
import {GridSortModel} from "@mui/x-data-grid";
import {GridPaginationModel} from "@mui/x-data-grid-pro";
import {getRqlQueryString} from "../filters/rqlUtils";

export interface GridFetchResult {
    rows?: any[],
    totalRows: number
}

export interface DGProviderSettings {
    prefix?: string,
    paginationModel?: GridPaginationModel,
    sortModel?: GridSortModel,
    filters?: DataFilter[],
    fieldsMapping?: {
        [key:string]: string
    }
}

export type GridFetchFunction = (query: string, pageSize: number, pageToken:string, skip: number) => Promise<GridFetchResult>;

export const useDataGridProvider = <T>(gridKey: string, fetchFunction: GridFetchFunction, initialSettings:DGProviderSettings): [] => {
    const [totalRows, setTotalRows] = useState(  )
    const {prefix} = initialSettings;

    const currentFilters = useSelector(dataFilterSelector(gridKey));
    const [paginationModel, setPaginationModel] = useState(initialSettings.paginationModel);
    const [sortModel, setSortModel] = useState(initialSettings.sortModel)
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [reloadCounter, setReloadCounter] = useState(0);

    const tryAgain = () => {
        setReloadCounter(reloadCounter+1)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setError(undefined);
                setIsLoading(true)
                const query = btoa(getRqlQueryString( prefix ? prefix : '', currentFilters, sortModel));
                const result = await fetchFunction(query, paginationModel.pageSize, undefined, paginationModel.pageSize*paginationModel.page);

                setTotalRows(result.totalRows);
                setData(result.rows);
                setIsLoading(false)

            } catch ( e ) {
                setError(e);
                setIsLoading(false)
            }
        }

        fetchData();
    }, [paginationModel, sortModel, currentFilters, reloadCounter])

    return [data, totalRows, isLoading, error, setPaginationModel, setSortModel, tryAgain];
}