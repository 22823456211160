import {showPopup} from '@biostrand/components/src/popup/popupsSlice';
import {Alert, Button, Stack} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {DatasetDetailsPopup} from "@biostrand/components/src/datasets/DatasetDetailsPopup";
import {getJobColumns} from "@biostrand/components/src/jobs/jobsGrid/jobsGridColumnUtils";
import JobRunInfoPopup from "@biostrand/components/src/jobs/JobRunInfoPopup";
import {ProtoJobStatus, ProtoJobTypeStatus} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {GridFetchFunction, useDataGridProvider} from "@biostrand/components/src/grid/useDataGridProvider";
import {MultipleValueFilterSelector} from "@biostrand/components/src/datasets/filters/MultipleValueFilterSelector";
import {DateFilterInput} from '@biostrand/components/src/datasets/filters/DateFilterInput';
import {uApi} from "@biostrand/components";
import {refreshJobTypesAction} from "@biostrand/components/src/jobs/jobsSlice";
import {useJobTypes} from "@biostrand/components/src/jobs/useJobTypes";

function uniq(arr): [] {
    return Array.from(new Set(arr))
}

const JOBS_RUN_STATUSES = [
    ProtoJobStatus.SUCCEEDED,
    ProtoJobStatus.RUNNING,
    ProtoJobStatus.SUBMITTED,
    ProtoJobStatus.FAILED,
    ProtoJobStatus.ABORTED,
    ProtoJobStatus.ABORTING,
    ProtoJobStatus.UNSPECIFIED
]

const initialSettings = {
    prefix: 'jobrun.',
    paginationModel: {
        page: 0,
        pageSize: 100
    },
    sortModel: [{field: 'last_updated_on', sort: 'desc'}],

}

const JobsHistory = (): JSX.Element => {
    const [jbT] = useJobTypes([ProtoJobTypeStatus.ACTIVE, ProtoJobTypeStatus.DELETED, ProtoJobTypeStatus.INACTIVE, ProtoJobTypeStatus.UNSPECIFIED]);

    const name = 'settingsJobsHistoryFilterKey';

    const jobHistoryFetch: GridFetchFunction = async (query: string, pageSize: number, pageToken: string, skip: number) => {
        const result = await uApi.getJobManagerApi().jobManagerGetJobRuns(query, pageSize, pageToken, skip);
        return {
            rows: result.data.job_runs,
            totalRows: Number(result.data.total_size)
        }
    }

    const [data = [], totalPages, isLoading, error, setPaginationModel, setSortModel, tryAgain] = useDataGridProvider(name, jobHistoryFetch, initialSettings);

    // const [currentJobsHistory] = useDataFilter<JobsHistoryItem>(name, jobHistory || [], initialFilterValues);
    const [jobTypeNames, setJobTypeNames] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (jbT) {
            const jobNames = uniq(jbT.map(jt => jt.name));
            setJobTypeNames(jobNames);
        }
    }, [jbT])

    useEffect(() => {
        dispatch(refreshJobTypesAction());
    }, [])

    const onOpenDataset = (datasetName) => {
        const key = 'ds-info-popup-key';

        dispatch(
            showPopup({
                key,
                title: 'Dataset details',
                content: React.createElement(DatasetDetailsPopup, {
                    popupKey: key,
                    datasetName,
                }),
            }),
        );
    }

    const onInfoClick = (jobRunId: string) => {
        const key = 'job-run-info-key';
        dispatch(
            showPopup({
                key,
                title: 'Job run details',
                content: React.createElement(JobRunInfoPopup, {
                    popupKey: key,
                    jobRunId,
                }),
            }),
        );
    };

    const JOBS_COLUMNS = getJobColumns(onInfoClick, onOpenDataset);

    return (
        <Stack direction={'column'} sx={{flexWrap: 'wrap', flex: 1, pt: 1, pb: 1, position: 'relative'}}>
            <Stack direction={"row"} sx={{mb: 0.5, alignItems: "center"}}>
                <MultipleValueFilterSelector values={jobTypeNames} filterKey={name} field={'job_type_name'}
                                             filterItemKey={"job_type_name"} fieldLabel={"Job Type"}/>
                <MultipleValueFilterSelector values={JOBS_RUN_STATUSES} filterKey={name} field={'status'}
                                             filterItemKey={"status"} fieldLabel={"Status"}/>
                <DateFilterInput
                    fieldLabel={"Created"}
                    filterKey={name}
                    field={'created_on'}
                    filterItemKey={"created_on"}/>
                <DateFilterInput
                    filterKey={name}
                    fieldLabel={"Last Updated"}
                    field={'last_updated_on'}
                    filterItemKey={"last_updated_on"}/>
            </Stack>
f
            {
                <DataGridPro
                    sx={{
                        'mr': 3,
                        '.MuiDataGrid-root': {border: '#f00 5px'},
                        'position': 'absolute',
                        'border': '5px',
                        'top': 36,
                        'bottom': 8,
                        'left': 0,
                        'right': 0,
                        'flex': 1,
                    }}
                    initialState={initialSettings}
                    pagination
                    sortingMode='server'
                    onSortModelChange={setSortModel}
                    rows={data}
                    loading={isLoading}
                    columns={JOBS_COLUMNS}
                    disableColumnFilter
                    disableSelectionOnClick
                    keepNonExistentRowsSelected
                    density={'standard'}
                    getRowHeight={() => 'auto'}
                    rowCount={totalPages || 1000}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                />
            }
            {error &&
            <Alert
                sx={{position:"absolute", bottom:24, }}
                severity="error"
                action={
                    <Button color="inherit" size="small" onClick={() => tryAgain()}>
                        try again
                    </Button>
                }
            >
                An error occurred while loading data: please try again or consider changing the filters.
            </Alert>}
        </Stack>
    );
};

export default JobsHistory;
