import {
  CheckCircleOutline,
  Close,
  ErrorOutline,
  ExpandLess,
  ExpandMore,
  QueryBuilderOutlined,
  WarningOutlined
} from '@mui/icons-material';
import {CircularProgress, IconButton, Paper, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fumCurrentFilesSelector, fumGetPopupState} from '../slices/datasets/fileUploadSelectors';
import {DatasetUploadInfo, FileUploadInfo, FileUploadPopupState} from '../slices/datasets/fileUploadTypes';

import {
  calculateFilesProgress,
  DatasetStatusSummary,
  DSStatuses,
  getFileStatusSummary,
  getStatusSummary
} from "../slices/datasets/fileUploadUtils";
import {setFileUploadPopupState} from '../slices/datasets/fileUploadManagerSlice';
import {push} from "connected-react-router";
import {SettingsIds} from "../applications/SettingsIds";

export const UploadProgressSmallPopup = (): JSX.Element | null => {
  const popupState: FileUploadPopupState | undefined = useSelector(fumGetPopupState);
  const fileList: FileUploadInfo[] = useSelector(fumCurrentFilesSelector);
  const [filesGroupedByDataset, setFilesGroupedByDataset] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (fileList) {
      const idMaps = {};
      const dsIds = [];
      fileList.forEach(fileInfo => {
        let item: DatasetUploadInfo | undefined = idMaps[fileInfo.context.dataset?.id];
        if (!item) {
          dsIds.push(fileInfo.context.dataset?.id);
          item = {
            dataset: fileInfo.context.dataset,
            files: [],
          }
          idMaps[fileInfo.context.dataset?.id] = item;
        }
        item.files.push(fileInfo);
      });

      const newFilesGroupedByDataset = dsIds.map(dsId => idMaps[dsId]);
      newFilesGroupedByDataset.forEach(uploadInfo => {
        uploadInfo.status = getStatusSummary(uploadInfo.files);
        uploadInfo.progress = calculateFilesProgress(uploadInfo.files);
      });

      setFilesGroupedByDataset(newFilesGroupedByDataset);
    } else {
      setFilesGroupedByDataset([]);
    }
  }, [fileList])

  useEffect(() => {
    const hasUnfinishedFiles = fileList.find(fileInfo => !getFileStatusSummary(fileInfo).isReady);
    setIsPopupVisible(!(!popupState || popupState === FileUploadPopupState.HIDDEN || !hasUnfinishedFiles))
  }, [popupState, fileList])


  const datasetColumns: GridColDef[] = [
    {
      field: 'datasetName',
      headerName: 'Dataset Name',
      flex: 1,
      width: 200,
      renderCell: (params: GridRenderCellParams<string>): JSX.Element | null => {
        const dataset = params.row.dataset;
        const status = params.row.status;
        const files = params.row.files;

        const progressLabel = `${status[DSStatuses.READY].length} of ${files.length} files uploaded`;
        return (
          <Stack sx={{p: 1, pt: 0.5, pb: 0.5}}>
            <Stack direction="row" spacing={1} sx={{alignItems: "baseline",}}>
              <Typography variant={"subtitle2"}>{dataset?.name}</Typography>
              {(dataset?.version && (dataset?.version !== 'v0.0.1')) ?
                <Typography variant={'caption'} sx={{opacity: 0.75}}>({dataset?.version})</Typography> : null}
            </Stack>
            <Typography variant={'caption'} sx={{opacity: 0.75}}>{progressLabel}</Typography>
          </Stack>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 50,
      minWidth: 50,
      renderCell: (params: GridRenderCellParams<DatasetStatusSummary>): JSX.Element | null => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const status: DatasetStatusSummary = params.row.status
        const files = params.row.files;

        if (status[DSStatuses.ERROR].length > 0) {
          return <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <WarningOutlined color={'error'}/>
          </Stack>
        }

        if (status[DSStatuses.FILE_MISSED].length > 0) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <ErrorOutline color={'warning'}/>
            </Stack>
          );
        }

        if (status[DSStatuses.READY].length === files.length) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <CheckCircleOutline color={'success'}/>
            </Stack>
          );
        }

        if (status[DSStatuses.PROGRESS].length > 0) {
          return (
            <CircularProgress size="32" sx={{width: 24}}/>
          )
        }


        if (status[DSStatuses.PENDING].length === files.length) {
          return <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <QueryBuilderOutlined/>
          </Stack>
        }

        return <div/>
      },
    }
  ];

  if (!isPopupVisible) return null;

  return (
    <Stack sx={{position: 'absolute', zIndex: 1600, bottom: 16, right: 16, width: 320}}>
      <Paper sx={{m: 0, p: 0}}>
        <Stack
          sx={{
            cursor: 'move',
            p: 1,
            pl: 2,
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            alignItems: "center"
          }}
          direction="row" spacing={1}
          id="draggable-dialog-title"
        >
          <Typography variant={"subtitle1"} sx={{flex: 1}}>Local files uploading...</Typography>
          {(popupState === FileUploadPopupState.OPEN) ?
            (<IconButton
              size={"small"}
              sx={{
                color: 'primary.contrastText',
              }}
              onClick={() => {
                dispatch(setFileUploadPopupState(FileUploadPopupState.COLLAPSED))
              }}
            ><ExpandLess/></IconButton>) :
            (<IconButton
              size={"small"}
              sx={{
                color: 'primary.contrastText',
              }}
              onClick={() => {
                dispatch(setFileUploadPopupState(FileUploadPopupState.OPEN))
              }}
            ><ExpandMore/></IconButton>)}
          <IconButton
            size={"small"}
            onClick={() => {
              dispatch(setFileUploadPopupState(FileUploadPopupState.HIDDEN))
            }}
            sx={{
              color: 'primary.contrastText',
            }}><Close/></IconButton>
        </Stack>

        {(popupState === FileUploadPopupState.OPEN) ?

          (<Stack sx={{flex: 1, height: 200}}>
            {fileList && fileList.length ? (
              <DataGrid
                sx={{
                  border: '5px',
                  '.MuiDataGrid-root': {border: '#f00 5px'},
                }}
                hideFooter
                headerHeight={0}
                sortingMode="server"
                rows={filesGroupedByDataset}
                columns={datasetColumns}
                disableColumnFilter
                getRowId={row => row.dataset.id}
                keepNonExistentRowsSelected
                rowsPerPageOptions={[50]}
                density={'standard'}
                onSelectionModelChange={(selectionModel, details) => {
                  dispatch(push(`/settings/${SettingsIds.DATASETS}/${selectionModel[0]}`))
                }}
              />
            ) : <Typography sx={{m: 3}}>History is empty</Typography>}
          </Stack>) : null}

      </Paper>
    </Stack>
  );
}
