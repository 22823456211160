import {Button} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {useCallback, useRef} from 'react';
import {useDropzone} from 'react-dropzone';

export const FilesSelectButton = ({...props}) => {
  const {children, onFileSelection, ...rest} = props
  const fileRef = useRef<HTMLInputElement>();

  const onDrop = useCallback(acceptedFiles => {
    onFileSelection && onFileSelection(acceptedFiles)
    //console.log(acceptedFiles)
  }, [onFileSelection]);

  const {getRootProps, getInputProps} = useDropzone({onDrop, multiple: true});

  return (
    <Stack
      {...getRootProps()}
    >
      <input ref={fileRef} {...getInputProps()} />
      <Button {...rest}>{children}</Button>
    </Stack>
  );
};

