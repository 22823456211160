import {Typography} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {useCallback, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';

export const FilesUploadArea = ({...props}) => {
  const {children, onFileSelection} = props
  const fileRef = useRef<HTMLInputElement>();
  const [t] = useTranslation();

  const onDrop = useCallback(acceptedFiles => {
    onFileSelection && onFileSelection(acceptedFiles)
    //console.log(acceptedFiles)
  }, [onFileSelection]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: true,});

  const {onClick, ...restRootProps} = getRootProps()

  return (
    <Stack
      {...restRootProps}
      sx={{
        borderWidth: 1,
        p: 2,
        pt: 0,
        mb: 2,
        position: 'relative',
        flex:1
      }}
      direction={'column'}
    >
      <input ref={fileRef} {...getInputProps()} />
      <Stack sx={{flex:1}}>{children}</Stack>
      {isDragActive && (
        <Stack sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: 'rgba(28,33,255,0.1)',
          borderRadius: 1,
          border: '1px dashed rgba(28,33,255,0.4)',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Stack sx={{p: 2, background: "#ffffff", borderRadius: 4}}>
            <Typography variant={'body2'}>{t('Drop the file here ...')}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

