import {DatasetManagerDatasetEntity} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

export enum FilePartStatus {
    DRAFT = 'draft',
    UPLOADING = 'uploading',
    READY = 'ready',
    ERROR = 'error',
}

export interface FilePart {
    fileId: string,
    uploadLink: string;
    ETag?: string;
    status?: FilePartStatus;
    index: string;
    progress?: number;
}

export enum FileUploadStatus {
    PENDING =  'pending',
    UPLOADING = 'uploading',
    FINALIZED = 'finalized',
    FINALIZATION_ERROR = 'finalizationError',
}

export interface DatasetUploadInfo {
    dataset?: DatasetManagerDatasetEntity,
    files?: FileUploadInfo[],
    status: FileUploadStatus,
    progress?: number;
}

export interface FileUploadInfo {
    fileName: string,
    fileId: string,
    fileKey: string,
    totalParts: number,
    fileSize: number,
    isAborted?: boolean,
    status?: FileUploadStatus,
    parts: FilePart[],
    file?: File,
    lastUpdated?: number,
    //just assume that file upload can be part of different flows. Keep context of flow by default is an dataset
    context: DatasetFromLocalFilesContext,
}

export interface DatasetFromLocalFilesContext {
    dataset?: DatasetManagerDatasetEntity,
}

export enum FileUploadManagerStatus {
    PENDING = 'pending',
    BUSY = 'busy',
}

export enum FileUploadPopupState {
  HIDDEN = 'hidden',
  COLLAPSED = 'collapsed',
  OPEN = 'open',
}

export interface FileUploadManagerState {
    currentFiles: FileUploadInfo[],
    filesByDatasetId: {[datasetId:string]: FileUploadInfo[] },
    popupState?: FileUploadPopupState,
    managerStatus: FileUploadManagerStatus,
}

export interface AppWithFileUploadManagerState {
    fileUploadManager: FileUploadManagerState,
}

export interface UploadPayload {
    filePart: FilePart,
    fileStatus: FileUploadInfo
}

export interface UploadProgressPayload extends UploadPayload{
    progress: number,
}

export interface UploadCompletePayload extends UploadPayload{
    ETag: string,
}

export interface PartUploadErrorPayload extends UploadPayload{
    error?: Error,
}

export interface FileResourcePayload {
    fileId: string,
    file: File
}

export interface FileFinalizeErrorPayload {
    fileId: string,
    status: FileUploadStatus
}

// this must be bigger than or equal to 5MB,
// otherwise AWS will respond with:
// "Your proposed upload is smaller than the minimum allowed size"
export const CHUNK_SIZE = 1024 * 1024 * 50;
