import {
    persistParsedToLocalStorage,
    retrieveParsedFromLocalStorage,
} from '@biostrand/articlelens/src/app/utils/localStorageHelpers';
import {all, call, cancel, cancelled, put, select, take, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {uApi} from '../../biostrandApi/uApi';
import {userApplicationContextKeySelector} from '../user/userSelectors';
import {createFileUploadChannel, FumChannelEvent} from './createFileUploadChannel';
import {refreshDatasetsAction} from './datasetsSlice';
import {
    CANCEL_FILE_UPLOADING,
    FINALIZE_DATASET,
    INIT_FILE_UPLOAD_MANAGER,
    initFileUploadManager,
    keepOnlyReadyFiles,
    KICK_UPLOAD_PROCESS,
    PART_ERROR,
    PART_IS_READY,
    partUploaded,
    partUploadError,
    partUploadProgress,
    removeFile,
    setCurrentFiles,
    setFileManagerBusy,
    setFileManagerPending,
    setFileStatus,
    UPLOAD_CANCELLED,
    UPLOAD_MANAGER_SAVE_STATE,
    UPLOAD_PROGRESS,
    uploadManagerSaveState,
} from './fileUploadManagerSlice';
import {
    fumCurrentFilesSelector,
    fumDatasetFilesSelector,
    fumFileDatasetIdSelector,
    fumFileSelector,
    fumIsFileReady,
    fumStatusSelector
} from './fileUploadSelectors';
import {
    FilePart,
    FilePartStatus,
    FileUploadInfo,
    FileUploadManagerStatus,
    FileUploadStatus,
    UploadCompletePayload,
} from './fileUploadTypes';
import {DatasetManagerFinalizeMultipartUploadRequest} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

interface KeyValueMap {
    [key: string]: string;
}

export function getFileManagerItems(appOrgUserKey: string): FileUploadInfo[] {
    return retrieveParsedFromLocalStorage<FileUploadInfo[]>(`${appOrgUserKey}.fileUploadManager`) || [];
}

export function saveFileUploadStatus(appOrgUserKey: string, status: FileUploadInfo[]): void {
    return persistParsedToLocalStorage<FileUploadInfo[]>(`${appOrgUserKey}.fileUploadManager`, status);
}

function* filePartUploadWorker(fileId: string, filePart: FilePart) {
    const fileStatus: FileUploadInfo = yield select(fumFileSelector, fileId);
    const channel = yield call(createFileUploadChannel, fileStatus, filePart);
    try {
        while (true) {
            const event = yield take(channel);

            if (event.type === PART_IS_READY || event.type === PART_ERROR) {
                return event;
            }

            if (event.type === UPLOAD_PROGRESS) {
                yield put(partUploadProgress(event.payload));
            }
            const fs: FileUploadInfo = yield select(fumFileSelector, fileId);
            if (fs.isAborted) {
                yield cancel();
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
            return {type: UPLOAD_CANCELLED};
        }
    }
}

const isAllPartsValid = (parts: FilePart[]): boolean => {
    return parts.every(part => part.status !== FilePartStatus.ERROR);
};

function* getNextFile() {
    const files: FileUploadInfo[] = yield select(fumCurrentFilesSelector);
    const fileReadyToUpload = files.find(
        f =>
            !!f.file &&
            isAllPartsValid(f.parts) &&
            f.status !== FileUploadStatus.FINALIZED &&
            f.status !== FileUploadStatus.FINALIZATION_ERROR,
    );
    return fileReadyToUpload;
}

function* getNextFilePart(fileId: string) {
    const fileStatus: FileUploadInfo = yield select(fumFileSelector, fileId);
    return fileStatus.parts.find(part => part.status === FilePartStatus.DRAFT);
}

function* handleCancelFileUploading(action) {
    const fileId: string = action.payload;
    const fileStatus: FileUploadInfo = yield select(fumFileSelector, fileId);
    yield put(removeFile(fileId))
}

function* finalizeFileUpload(fileId: string) {
    const fileStatus: FileUploadInfo = yield select(fumFileSelector, fileId);
    const validationsParts: KeyValueMap = fileStatus.parts.reduce((etagMap: KeyValueMap, currentValue) => {
        etagMap[currentValue.index] = currentValue.ETag as string;
        return etagMap;
    }, {});

    const request: DatasetManagerFinalizeMultipartUploadRequest = {
        identifier: {id: fileStatus.context.dataset?.id},
        upload_id: fileStatus.fileId,
        key: fileStatus.fileKey,
        parts: validationsParts,
        is_open: true
    };

    try {
        const result = yield call([uApi.getDatasetManagerApi(), 'datasetManagerFinalizeMultipartUpload'], request);
        yield put(setFileStatus({fileId, status: FileUploadStatus.FINALIZED}));
    } catch (e) {
        yield put(setFileStatus({fileId, status: FileUploadStatus.FINALIZATION_ERROR}));
    } finally {
        yield put(uploadManagerSaveState());
    }
}

function unloadHandler(e: BeforeUnloadEvent) {
    // unfortunately the return value doesn't work in the modern browsers
    e.returnValue =
        'You have active uploading.\n\nAre you sure you want to navigate away from this page?\n\nPress OK to continue or Cancel to stay on the current page';
    return 'You have active uploading.\n\nAre you sure you want to navigate away from this page?\n\nPress OK to continue or Cancel to stay on the current page';
}

function watchClose() {
    window.addEventListener('beforeunload', unloadHandler);
}

function unWatchClose() {
    window.removeEventListener('beforeunload', unloadHandler);
}

function* runFileUploadWorker() {
    yield put(setFileManagerBusy());
    watchClose();
    let fileStatus: FileUploadInfo | undefined = yield getNextFile();

    while (fileStatus) {
        const {fileId} = fileStatus;
        let nextFilePart: FilePart = yield getNextFilePart(fileId);
        while (nextFilePart) {
            yield put(setFileStatus({fileId, status: FileUploadStatus.UPLOADING}));
            const result: FumChannelEvent = yield filePartUploadWorker(fileId, nextFilePart);

            if (result.type === PART_ERROR) {
                yield put(partUploadError(result.payload as UploadCompletePayload));
                yield put(uploadManagerSaveState());
                break;
            }

            if (result.type === UPLOAD_CANCELLED) {
                yield put(removeFile(fileId));
                //todo: remove file from server (should be dataset deleted?);
                break;
            }

            if (result.type === PART_IS_READY) {
                yield put(partUploaded(result.payload as UploadCompletePayload));
                yield put(uploadManagerSaveState());
            }

            nextFilePart = yield getNextFilePart(fileId);
        }

        const isFileReady = yield select(fumIsFileReady, fileId);
        if (isFileReady) {
            yield finalizeFileUpload(fileId);

            const datasetId = yield select(fumFileDatasetIdSelector, fileId);
            if (datasetId) {
                const isDatasetReady = yield checkDatasetReady(datasetId);
                if (isDatasetReady) {
                    yield finalizeDataset(datasetId)
                }
            }
        }

        fileStatus = yield getNextFile();
    }

    yield put(setFileManagerPending());
    unWatchClose();
}

function* checkDatasetReady(datasetId: string) {
    const dsFiles = yield select(fumDatasetFilesSelector, datasetId);
    const firstNotReady = dsFiles.find((fu: FileUploadInfo) => fu.status !== FileUploadStatus.FINALIZED)
    return !firstNotReady;
}

function* handleFinalizeDataset(action) {
    const datasetId = action.payload;
    yield finalizeDataset(datasetId);
}

export function* finalizeDataset(datasetId: string) {
    yield put(keepOnlyReadyFiles(datasetId));
    const dsFiles = yield select(fumDatasetFilesSelector, datasetId);
    const parts = dsFiles.map((fileInfo: FileUploadInfo) => {
        const validationsParts: KeyValueMap = fileInfo.parts.reduce((etagMap: KeyValueMap, currentValue) => {
            etagMap[currentValue.index] = currentValue.ETag as string;
            return etagMap;
        }, {});
        return {
            upload_id: fileInfo.fileId,
            key: fileInfo.fileKey,
            part_tags: validationsParts
        }
    });

    try {
        const result = yield call([uApi.getBFFPortalApi(), 'finalizeLocalUpload'], {
            dataset: {
                id: datasetId
            },
            parts,
            is_open: false
        });
        console.log(result)
        //yield delay(1000);
        yield put(refreshDatasetsAction());
        ///const result = yield call([uApi.getDatasetManagerApi(), 'datasetManagerUpdateDataset'], datasetId, {status: DatasetStatusCode.COMPLETE});
    } catch (e) {
        console.log('finalizeDataset === > error', e);
    } finally {

    }
}

const KEEP_FILE_PERIOD = 1000 * 60 * 60 * 24 * 7;

function shouldKeepFile(fileInfo: FileUploadInfo) {
    if (fileInfo.status !== FileUploadStatus.FINALIZED) {
        return true;
    }
    const currentTime = new Date().getTime();
    return fileInfo.lastUpdated > currentTime - KEEP_FILE_PERIOD
}

function removeOutdatedFiles(fileList: FileUploadInfo[]): FileUploadInfo[] {
    const datasetToKeepMap = {};
    const currentTime = new Date().getTime();
    fileList.forEach(fileInfo => {
        if (!isFinite(Number(fileInfo.lastUpdated))) {
            fileInfo.lastUpdated = currentTime
        }

        if (shouldKeepFile(fileInfo)) {
            const dsId = fileInfo.context?.dataset?.id;
            if (dsId) {
                datasetToKeepMap[dsId] = true;
            }
        }
    })
    return fileList.filter(fi => datasetToKeepMap[fi.context?.dataset?.id || '']);
}

function* handleInitFileUploadManager(action: ActionType<typeof initFileUploadManager>) {
    const appOrgUserKey: string = yield select(userApplicationContextKeySelector);
    const fileUploadList = getFileManagerItems(appOrgUserKey);

    const cleanedFileList = removeOutdatedFiles(fileUploadList);
    saveFileUploadStatus(appOrgUserKey, cleanedFileList);

    yield put(setCurrentFiles(cleanedFileList));
}

function* handleFileUploadManagerSaveState(action: ActionType<typeof uploadManagerSaveState>) {
    const status = yield select(fumCurrentFilesSelector);
    const statusesToSave = status.map((item: FileUploadInfo) => ({...item, file: undefined}));

    const appOrgUserKey: string = yield select(userApplicationContextKeySelector);
    saveFileUploadStatus(appOrgUserKey, statusesToSave);
}

function* handleKickProgress(action: ActionType<typeof uploadManagerSaveState>) {
    const status = yield select(fumStatusSelector);
    if (status === FileUploadManagerStatus.PENDING) {
        yield runFileUploadWorker();
    }
}

export function* fileUploadSaga() {
    yield all([
        yield takeEvery(INIT_FILE_UPLOAD_MANAGER, handleInitFileUploadManager),
        yield takeEvery(UPLOAD_MANAGER_SAVE_STATE, handleFileUploadManagerSaveState),
        yield takeEvery(CANCEL_FILE_UPLOADING, handleCancelFileUploading),
        yield takeEvery(FINALIZE_DATASET, handleFinalizeDataset),
        yield takeEvery(KICK_UPLOAD_PROCESS, handleKickProgress),
    ]);
}
