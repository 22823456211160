import {PopupWindow} from '@biostrand/components/src/popup/PopupWindow';
import {FormikHelpers} from 'formik/dist/types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {OrganizationRole} from '../../../../core/organization/organizationTypes';
import {InviteUserForm} from './InviteUserForm';
import {inviteUserRequestAction} from './usersSlice';
import {InlineObject1} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

export type InviteData =  InlineObject1 & { validateEmail?:string };

interface Props {
    open: boolean;
    onResult?: () => void;
    onCancel?: () => void;
}

export const InviteUserPopup = (props: Props): JSX.Element => {
    const { open, onCancel } = props;
    const [t] = useTranslation();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const dispatch = useDispatch();
    let fActions: FormikHelpers<InviteData> | undefined = undefined;
    let fCallback: (error: string | undefined, actions: FormikHelpers<InviteData>) => void | undefined;



    const initialValues: InviteData = {
        organization_role: OrganizationRole.REGULAR,
    };

    const onFormCancel = () => {
        onCancel && onCancel();
        setIsBusy(false);
    };

    const userInvited = (error?: string) => {
        if (fCallback && fActions) {
            fCallback(error, fActions);
        }

        setIsBusy(false)
    };

    const handleSubmit = (
        values: InviteData,
        actions: FormikHelpers<InviteData>,
        callback: (error: string | undefined, actions: FormikHelpers<InviteData>) => void,
    ) => {
        fActions = actions;
        fCallback = callback;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {validateEmail, ...cleanedValues} = values;

        setIsBusy(true)
        dispatch(
            inviteUserRequestAction({
                invite: cleanedValues,
                callback: userInvited,
            }),
        );
    };

    return (
        <PopupWindow open={open} title={t('Invite new user')} onClose={onCancel} disableClose={isBusy}>
            <InviteUserForm onCancel={onFormCancel} onSubmit={handleSubmit} initialValues={initialValues} />
        </PopupWindow>
    );
};
