import {DatasetManagerDatasetEntity,} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {GridSortModel} from '@mui/x-data-grid';
import {GridSelectionModel} from '@mui/x-data-grid/models/gridSelectionModel';
import * as React from 'react';
import {MenuItemDescription} from '../../grid/menuTypes';
import {COLUMN_NAMES,} from './datasetColumns';
import {DatasetsListBase} from "./DatasetsListBase";
import {showPopup} from "../../popup/popupsSlice";
import {useDispatch} from "react-redux";
import {DatasetDetailsPopup} from "../DatasetDetailsPopup";

export type DatasetsListProps = {
  selectedDatasets?: GridSelectionModel;
  datasets: DatasetManagerDatasetEntity[] | undefined;
  showMenu?: boolean;
  isDatasetsLoading: boolean;
  initialSort?: GridSortModel;
  menuItems?: MenuItemDescription[];
  onMenuAction?: (row: DatasetManagerDatasetEntity[], action: string) => void;
  columns?: COLUMN_NAMES[];
  minHeight?: number;
};

export const DatasetsList = (props: DatasetsListProps): JSX.Element => {
  const {
    datasets,
    minHeight = 400,
    initialSort = [{field: 'last_updated_on', sort: 'desc'}],
    columns = [COLUMN_NAMES.name_and_version, COLUMN_NAMES.last_updated_on, COLUMN_NAMES.files],
  } = props;

  const dispatch = useDispatch();

  const onDatasetOpen = (datasetId : string | number) => {
    const key = 'ds-info-popup-key';
    const ds = datasets?.find(d => d.id === datasetId);
    dispatch(
      showPopup({
        key,
        title: 'Dataset details',
        content: React.createElement(DatasetDetailsPopup, {
          popupKey: key,
          datasetName: ds?.name || '',
          datasetVersion: ds?.version
        }),
      }),
    );
  }

  return <DatasetsListBase datasets={datasets}
                           hideSearch
                           minHeight={minHeight}
                           hideHeader
                           hideFooter
                           initialSort={initialSort}
                           isDatasetsLoading={!datasets}
                           onDatasetSelect={console.log}
                           onDatasetOpen={(datasetIds) => onDatasetOpen(datasetIds[0])}
                           columns={columns}
  />
};
