import {
    AppWithFileUploadManagerState,
    FilePartStatus,
    FileUploadInfo,
    FileUploadManagerStatus,
    FileUploadPopupState,
} from './fileUploadTypes';
import {calculateFileUploadProgress} from "./fileUploadUtils";

export const fumCurrentFilesSelector = (state: AppWithFileUploadManagerState): FileUploadInfo[] => state.fileUploadManager.currentFiles;

export const fumFileSelector = (state: AppWithFileUploadManagerState, fileId: string): FileUploadInfo | undefined => {
  const currentFiles = fumCurrentFilesSelector(state);
  return currentFiles.find(fs => fs.fileId === fileId);
};

export const fumIsDatasetInProgress = (datasetId: string) => (state: AppWithFileUploadManagerState): boolean => {
    return !!state.fileUploadManager.filesByDatasetId[datasetId];
};

export const fumFileDatasetIdSelector = (state: AppWithFileUploadManagerState, fileId: string): string | undefined => {
  const currentFiles = fumCurrentFilesSelector(state);
  const file = currentFiles.find(fs => fs.fileId === fileId);
  return file?.context?.dataset?.id;
};

export const fumDatasetFilesSelector = (state: AppWithFileUploadManagerState, datasetId: string): FileUploadInfo[] => {
  return state.fileUploadManager.filesByDatasetId[datasetId];
};

export const fumStatusSelector = (state: AppWithFileUploadManagerState): FileUploadManagerStatus =>
  state.fileUploadManager.managerStatus;

export const fumIsFileReady = (state: AppWithFileUploadManagerState, fileId: string) => {
  const cf = fumFileSelector(state, fileId);
  const validParts = cf?.parts.filter(part => part.ETag && part.status === FilePartStatus.READY);
  return validParts?.length === cf?.parts.length;
};

export const fumGetPopupState = (state: AppWithFileUploadManagerState): FileUploadPopupState | undefined => {
  return state.fileUploadManager.popupState;
};

export const hasPartError = (fileInfo: FileUploadInfo) => {
  return !!fileInfo.parts.find(part => part.status === FilePartStatus.ERROR);
};

export const getFileProgressSelector = ( state: AppWithFileUploadManagerState, fileId: string): number => {
  const fileInfo = fumFileSelector(state, fileId);
  return  fileInfo ? calculateFileUploadProgress(fileInfo) : 0;
}
