import {uApi} from '@biostrand/components';
import {useEffect, useState} from 'react';
import {getRqlQueryString} from "../filters/rqlUtils";
import {FieldFilterType, FilterType} from "../filters/dataFiltersTypes";
import {ProtoJobType, ProtoJobTypeStatus} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";

export const useJobTypes = (statusList?: ProtoJobTypeStatus[]) => {
    const statuses = statusList ? statusList : [ProtoJobTypeStatus.ACTIVE];
    const prefix = ''; //'jobtypes.'
    const [isLoading, setIsloading] = useState(false);
    const [jobTypes, setJobTypes] = useState<Array<ProtoJobType> | undefined>(undefined);

    useEffect(() => {
        const runQ = async () => {
            setIsloading(true);
            try {
                const query = btoa(getRqlQueryString(prefix ? prefix : '', [{
                    field: 'status',
                    type: FilterType.FIELD,
                    fieldType: FieldFilterType.ENUM,
                    filterItemKey: 'statusKey',
                    value: {$in: statuses}}],

                    [{field:'jobtype.name', sort: "asc"}]));
                const result = await uApi.getJobManagerApi().jobManagerGetJobTypes(query, 10000);
                setJobTypes(result.data.job_types);
            } finally {
                setIsloading(false);
            }
        };

        runQ();
    }, []);


    return [jobTypes, isLoading];
};
