import {Button, TextField, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useFormik} from 'formik';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {createOrganizationRequestAction} from '../../../core/organization/organizationActions';
import {OrganizationRole} from '../../../core/organization/organizationTypes';
import {RouteUrl} from '../../../routeUrl';
import {LoadingButton} from "@mui/lab";
import {sleep} from "@biostrand/components/src/utilites/sleep";
import {UserManagerCreateOrganizationRequest} from "@biostrand/biostrandapi/javascript/dist/UserManagerApi";

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(12),
        height: 468,
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
    },
    input: {
        width: 420,
        height: theme.spacing(9),
        marginBottom: theme.spacing(3),
    },
    buttonMargin: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    subTitle: {
        marginBottom: theme.spacing(5),
    },
}));

const RegisterOrganizationFormComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const validate = (values) => {
        const errors = {};

        if (!values.organization) {
            errors.organization = t('Required');
        }

        if (!values.email) {
            errors.email = t('Required');
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.email = t('Invalid email address');
        }

        if (!values.validateEmail) {
            errors.validateEmail = t('Required');
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.validateEmail = t('Invalid email address');
        }

        if (values.email && values.validateEmail && !errors.validateEmail && !errors.email && (values.email !== values.validateEmail)) {
            errors.validateEmail = errors.email = t('Emails are not match');
        }

        return errors;
    }

    const registerForm = useFormik({
        initialValues: {
            email: '',
            validateEmail: '',
            organization: '',
        },
        validate,
        onSubmit: async values => {

            const request: UserManagerCreateOrganizationRequest = {
                email: values.email,
                organization_name: values.organization,
                organization_role: OrganizationRole.ADMIN,
            };
            console.log(request)
            dispatch(createOrganizationRequestAction(request));
            await sleep(5000);
        },
    });

    return (
        <form className={classes.form} onSubmit={registerForm.handleSubmit}>
            <div>
                <Typography variant='h4' className={classes.title}>
                    {t('Register your organization')}
                </Typography>
                <Typography variant='body1' className={classes.subTitle}>
                    {t('Explore the tool and access all our features')}
                </Typography>
            </div>

            <TextField
                className={classes.input}
                id='email'
                label={t('Email')}
                variant='outlined'
                name='email'
                value={registerForm.values.email}
                onChange={registerForm.handleChange}
                onBlur={registerForm.handleBlur}
                error={registerForm.touched.email && Boolean(registerForm.errors.email)}
                helperText={registerForm.touched.email && registerForm.errors.email}
            />

            <TextField
                className={classes.input}
                id='validateEmail'
                label={t('Repeat email')}
                variant='outlined'
                name='validateEmail'
                onBlur={registerForm.handleBlur}
                value={registerForm.values.validateEmail}
                onChange={registerForm.handleChange}
                error={registerForm.touched.validateEmail && Boolean(registerForm.errors.validateEmail)}
                helperText={registerForm.touched.validateEmail && registerForm.errors.validateEmail}
            />

            <TextField
                className={classes.input}
                id='organization'
                label={t('Organization')}
                variant='outlined'
                name='organization'
                onBlur={registerForm.handleBlur}
                value={registerForm.values.organization}
                onChange={registerForm.handleChange}
                error={registerForm.touched.organization && Boolean(registerForm.errors.organization)}
                helperText={registerForm.touched.organization && registerForm.errors.organization}
            />

            <div className={classes.buttons}>
                <LoadingButton
                    loading={registerForm.isSubmitting}
                    variant='contained'
                    color='primary'
                    size='large'
                    className={classes.buttonMargin}
                    onClick={() => {
                        registerForm.handleSubmit();
                    }}>
                    {t('Sign up')}
                </LoadingButton>
                <Button
                    sx={{textTransform: "none"}}
                    component={RouterLink}
                    color='primary'
                    size='large'
                    to={RouteUrl.Login}
                    className={classes.buttonMargin}>
                    {t('Back to login')}
                </Button>
            </div>
        </form>
    );
};

const RegisterOrganizationForm = RegisterOrganizationFormComponent;

export default RegisterOrganizationForm;
